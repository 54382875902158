<template>
  <v-sheet class="d-flex justify-center" dark    :color="wsDARKER" >


    <v-sheet class="d-flex align-center" height="100"  width="1140" color="transparent">
      <v-sheet width="100%" class="d-flex justify-space-between align-center" color="transparent">

        <div v-if="displayShow(['sm']) && showMobileMenu"  >
          <v-btn @click="showMobileMenu = !showMobileMenu" class="ml-4" v-if="displayShow(['sm'])" icon> <v-icon>mdi-menu</v-icon></v-btn>
          <v-sheet v-if="displayShow(['sm']) && showMobileMenu"
                   v-click-outside="mobileOutsideClick"
                   :color="wsBACKGROUND"
                   class="pa-3"
                   elevation="1"
                   style="position: absolute; top:100px; left: 0; right: 0; z-index: 999" >
            <v-btn @click="showMobileMenu = false" block to="/" text class="noCaps">
              <h4 class="font-weight-medium" >Головна</h4>
            </v-btn>
            <v-btn @click="showMobileMenu = false" block to="/about" text class="noCaps">
              <h4 class="font-weight-medium" >Про Нас</h4>
            </v-btn>
            <v-btn @click="showMobileMenu = false" block to="/news" text class="noCaps">
              <h4 class="font-weight-medium" >Новини</h4>
            </v-btn>
            <v-btn @click="showMobileMenu = false" block to="/documentation" text class="noCaps">
              <h4 class="font-weight-medium" >Документація</h4>
            </v-btn>
            <v-btn @click="showMobileMenu = false" block to="/contacts" text class="noCaps">
              <h4 class="font-weight-medium" >Контакти</h4>
            </v-btn>
          </v-sheet>

        </div>

        <router-link to="/">
          <img   class="text-center "
                 height="40"
                 alt="WeStudy"
                 src="@/assets/brand/logo_fine_white.png"
          />
        </router-link>


        <div v-if="displayHide(['sm'])" class="d-flex align-center">
          <v-btn to="/" text class="noCaps">
            <h4 class="font-weight-medium" >{{ $t('MainPage') }}</h4>
          </v-btn>
          <v-btn to="/about" text class="noCaps">
            <h4 class="font-weight-medium" >{{ $t('AboutPage') }}</h4>
          </v-btn>
          <v-btn to="/news" text class="noCaps">
            <h4 class="font-weight-medium" >{{ $t('NewsPage') }}</h4>
          </v-btn>
          <v-btn to="/documentation" text class="noCaps">
            <h4 class="font-weight-medium" >{{ $t('DocumentationPage') }}</h4>
          </v-btn>
          <v-btn to="/contacts" text class="noCaps">
            <h4 class="font-weight-medium" >{{ $t('ContactsPage') }}</h4>
          </v-btn>
          <wsLangSelect class="ml-3" />
<!--          <ws-lang-select />-->

        </div>

        <div class="d-flex align-center">
          <div v-if="!$store.state.auth.loggedIn">
            <v-btn v-if="displayHide('sm')"
                   @click="toggleLoginMenu"
                   class="noCaps wsRoundedButton mr-4 px-6"
                   elevation="0"
                   small
                   dark :color="wsACCENT" >
              Увійти
            </v-btn>
            <v-btn   @click="toggleLoginMenu" :color="wsACCENT" class="mr-4" v-else icon small><v-icon>mdi-login</v-icon></v-btn>
            <v-btn small v-if="displayHide(['sm'])" elevation="0"  dark :color="wsDARK" class="noCaps wsRoundedButton px-5">Спробувати</v-btn>
          </div>
          <userDropdown v-else />

        </div>



      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script>
import {mapMutations} from "vuex";
import userDropdown from "@/components/UI/userDropdown";
import wsLangSelect from "@/components/UI/wsLangSelect";

export default {
  name: "homeHeader",
  components  : {
    userDropdown,
    wsLangSelect
  },
  data() {
    return {
      showMobileMenu : false
    }
  },
  watch : {
    windowSize(val) {
     if ( val !== 'sm') { this.showMobileMenu = false }
    }
  },
  methods : {
    ...mapMutations(['toggleLoginMenu']),

    mobileOutsideClick() {
      if ( this.showMobileMenu === true ) {
        this.showMobileMenu = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>