<template>
  <v-sheet class="d-flex justify-center" dark :color="wsDARK" >
    <v-sheet  :class="displayHide(['sm']) ? 'pa-16' : 'pa-6'" color="transparent" width="1200">

      <v-row>
        <v-col cols="12" xs="12"  sm="12" md="12" lg="4" xl="4">
          <v-img   class="text-center  d-block" :class="displayShow(['sm']) ? 'mx-auto' : '' "
                 height="57" width="147"
                 alt="WeStudy"
                 src="@/assets/brand/logo_fine_white.png"
                   transition="xxx"
          />
          <h5 class="mt-3" :class="displayShow(['sm']) ? 'text-center' : '' ">
            <span >FineTrading™</span>
            <span class="font-weight-medium">
              : Сучасні рішення для управління бізнесом
            </span>
          </h5>
          <div class="mt-6 d-flex" :class="displayShow(['sm']) ? 'justify-center' : '' ">
            <v-btn target="_blank"  href="https://www.facebook.com/WeStudy-TM-Сучасні-цифрові-рішення-для-управління-навчальним-процесом-111924228037039"  class="mr-3" :color="wsDARK" icon><v-icon>mdi-facebook</v-icon></v-btn>
            <v-btn target="_blank"  href="https://m.youtube.com/channel/UCB7XOI1dApLUo7Q905hHkww"  class="mr-3" :color="wsDARK" icon><v-icon>mdi-youtube</v-icon></v-btn>
<!--            <v-btn  class="mr-3" :color="wsDARK" icon><v-icon>mdi-instagram</v-icon></v-btn>-->
          </div>
        </v-col>
        <v-col cols="6"  xs="6"   sm="6"  md="6" lg="4"  xl="4">
          <div :class="displayHide(['sm']) ? 'ml-12' : ''"  >
            <h5>Можливості</h5>
            <br>
            <h5 class="font-weight-medium">Сучасний веб сайт</h5>
            <h5 class="font-weight-medium">Системи обліку товарів та складів</h5>
            <h5 class="font-weight-medium">Інтеграція модулів</h5></div>

        </v-col>
        <v-col cols="6"  xs="6"   sm="6"  md="6" lg="4"  xl="4">
          <div :class="displayHide(['sm']) ? 'ml-12' : ''" >
            <h5>Технології</h5>
            <br>
            <h5 class="font-weight-medium">Мобільні додатки</h5>
            <h5 class="font-weight-medium">Чат боти</h5>
            <h5 class="font-weight-medium">Інтеграції</h5></div>
        </v-col>
      </v-row>
      <v-divider class="mt-6 " :class="displayShow(['sm']) ? 'mb-6 mt-6' : 'mb-1' "/>

      <div :class="displayHide(['sm']) ? 'd-flex align center justify-space-between' : ''" >
        <h5 :class="displayShow(['sm']) ? 'text-center' : '' " class="caption font-weight-light text-center">
        © 2022 - FineTrading™ : Business Management System
        </h5>
        <div class="d-flex align center justify-center"  >
          <a href="https://www.visa.com.ua"><img style="height: 40px"  class="" src="@/assets/brand/visa.png" alt="" /></a>
          <a href="https://www.liapay.ua"><img style="height: 25px"  class="ml-2 mt-2" src="@/assets/brand/liqpay.png" alt="" /></a>
        </div>
      </div>



    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
name: "homeFooter"
}
</script>

<style scoped>

</style>